<template>
  <!--线下实训预约列表  -->
  <div class="content-wraper-yuanmi">
    <!-- <div class="backButton" v-if="!theme || theme=='junmin'"> -->
    <div class="backButton">

      <span @click="goBack">
          <i class="back el-icon-arrow-left"></i>
          返回
      </span>
    </div>
    <div class="title-search">
      <span class="title-a">
        预约线下实训
        <span class="tip">说明：一个线下实训只可预约一个实训课程</span>
      </span>
      <el-input class="search" placeholder="请输入关键字检索" v-model="searchKey">
        <el-button class="btn" slot="append" icon="el-icon-search" @click="onSearch"></el-button>
      </el-input>
    </div>
    <ul class="subscribe-list" v-if="subscribList.length">
      <li class="subscribe-item" v-for="(item, index) in subscribList" :key="index">
        <p class="title-2">{{item.trainingName}}</p>
        <p class="address">实训基地: {{item.trainingAddressName}}</p>
        <div class="bottom">
          <p class="address-2">地址: {{item.trainingAddress}}</p>
          <div class="btn" :class="item.isReserved? 'active':''"  @click="subscribe(item)" >{{item.isReserved?'取消预约' : '预约'}}</div>
        </div>
      </li>
    </ul>
    <div v-else-if="!subscribList.length && !isSearch" class="list-none">
      <img src="../../../assets/images/common/nodata.png" alt="" />
      <p>暂无可预约课程呦～</p>
    </div>
    <div v-else class="list-none">
      <img src="../../../assets/images/common/no-search-data.png" alt="" />
      <p>没有搜索到相关内容哟～</p>
    </div>
    <paging  v-show="subscribList.length" @numChange="numChange" :pageInfo="pageInfo" class="paging pagingCustom" />
    <el-dialog
      custom-class="dialog"
      :visible.sync="showTip"
      :before-close="handleClose1"
    >
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="titles">提示</p>
      <p class="text">{{!isSame? '您已存在预约记录，是否更换预约?':'确定取消预约吗？'}}</p>
      <div class="bottom-btn">
        <div class="left-btn"  @click="cancle" 
          >取消</div>
        <div class="right-btn"  @click="confirm" 
          >确认</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Paging from './components/pagenation.vue'
export default {
  name: 'subscribeList',
  components: {
    Paging
  },
  data() {
    return {
      searchKey: '',
      pageInfo: {
        total: 0,
        size: 10,
        pageNum: 1,
        pageSize: 10
      },
      showTip: false,
      subscribList: [
        // {id: '111',isReserved: 0, trainingAddress: '收到贵公司的感受风寒好地方发士大夫山豆根豆腐干山豆根收到反倒是分公司分公司', trainingAddressName: '犯得上发射点发的', trainingBaseId: '11', trainingName: '瑞特豆腐的发放购房上轨道恢复骨结核疯狂考虑进来郭德纲得分回复花大会的'},
        // {id: '222',isReserved: 0, trainingAddress: '的格式规范土地和烦得很东方红房管局今天的反对鬼地方鬼地方贝多芬', trainingAddressName: '十大发是发发发打发', trainingBaseId: '22', trainingName: '打发打发发生'},
        // {id: '333',isReserved: 0, trainingAddress: '的方式度过回复的韩国军方古典风格的水果蛋糕复苏步伐的发电方式灌水士大夫大师傅似的刚刚发生过是灌水灌水灌水f', trainingAddressName: '发射点发生如果发士大夫敢死队', trainingBaseId: '33', trainingName: '发生过古典风格撒打发'}
      ],
      currentItem: {},
      isSame: false,
      isSearch: false,
    }
  },
  
  created() {
    this.taskId = this.$route.query.taskId
  },
  mounted() {
     this.getUserTaskTrainingList()
  },
  methods: {
    numChange(value) {
      this.pageInfo.pageNum = value.currentPage
      this.pageInfo.pageSize = value.currentSize 
      this.getUserTaskTrainingList()
    },
    handleClose1() {
      this.showTip = false
    },
    goBack(){
      this.$router.go(-1)
    },
    confirm() {
      if(this.isSame) { // 取消预约
        this.cancelTaskTraining()
      } else {
        this.replaceTaskTraining()
      }
    },
    cancle() {
      this.showTip = false
    },
    async subscribe(item) {
      // this.readyExist()
      this.clickItem = {...item}
      let result = await this.isUserTaskTrainingReserved()
      // result.then((data) => {
      item.isReserved ? this.isSame = true : this.isSame = false 
      if(result) { // true, 已经预约
        this.showTip = true
      } else {
        this.reserveTaskTraining(item)
      }
      // })
      
    },
    // 搜索
    onSearch() {
      this.pageInfo.pageNum = 1
      this.getUserTaskTrainingList()
      this.isSearch = true
    },
    // 获取用户指定培训任务可选择线下实训列表及预约状态
    async getUserTaskTrainingList() {
      const params = {
        searchKey: this.searchKey,
        taskId: this.taskId,
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize
      }
      let result = await this.$api.openSubcribe.getUserTaskTrainingList(params)
      if(result.success) {
          const {list, total, size, pageNum} = result.data
          this.subscribList = list || []
          this.pageNum = result.data.pageNum
          this.pageInfo.total = total
          this.pageInfo.size = size
          this.pageInfo.pageNum = pageNum 
      }
    },
     // 判断用户指定培训任务是否已经预约线下实训项目
    async isUserTaskTrainingReserved() {
      const params = {
        taskId: this.taskId
      }
      let result = await this.$api.openSubcribe.isUserTaskTrainingReserved(params)
      if(result.success) {
        this.isReserved = result.data
      }
      return result.data

    },
    // 重新预约新的线下实训项目并取消旧的预约
    async replaceTaskTraining() {
      const params = {
        taskId: this.taskId,
        trainingCourseId: this.clickItem.id
      }
      let result = await this.$api.openSubcribe.replaceTaskTraining(params)
      if(result.success) {
        // this.isReserved = result.data
        this.subscribList = []
        this.getUserTaskTrainingList()
        
        this.$toast({
            message: this.isSame?'取消成功':'预约成功',
            position: 'middle',
        });  
      }
      this.showTip = false
       this.$refs.list.check((pos) => {
          console.log(pos, '===')
        })
    },
    //  预约指定线下实训项目
    async reserveTaskTraining(item) {
      if(item.isReserved) { // 
        this.isSame = true 
        this.cancelTaskTraining()
      } else {
        const params = {
          taskId: this.taskId,
          trainingCourseId: item.id
        }
        let result = await this.$api.openSubcribe.reserveTaskTraining(params)
        if(result.success) {
          this.subscribList = []

          this.getUserTaskTrainingList()

          this.$toast({
              message: '预约成功',
              position: 'middle',
          });  
        }
        this.showTip = false
      }
      
      
    },
    // 取消预约指定线下实训项目
    async cancelTaskTraining() {
       const params = {
        taskId: this.taskId,
        trainingCourseId: this.clickItem.id
      }
      let result = await this.$api.openSubcribe.cancelTaskTraining(params)
      if(result.success) {
        // this.isReserved = result.data
        this.subscribList = []

        this.getUserTaskTrainingList()
        this.$toast({
            message: this.isSame?'取消成功':'预约成功',
            position: 'middle',
        }); 
      }
      this.showTip = false
    }
  }
}
</script>
<style lang="stylus" >
.content-wraper-yuanmi
  .el-dialog 
    width 100%
  .el-dialog__header
    padding 0
  .el-dialog__body
    padding 0
    display: flex 
    flex-direction: column
    align-items: center
    justify-content: center
</style>
<style lang="stylus" scope>

.content-wraper-yuanmi
  width 1200px
  // position relative
  font-family: PingFangSC-Regular, PingFang SC
  margin 0 auto
  .backButton 
    padding: 24px 0;
    span
      cursor: pointer;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #316FFF;
      line-height: 28px;
      .back
        margin-right: -7px;
        color: #316fff;
        font-size: 18px;
        font-weight: bold;
  .title-search
    display: flex 
    justify-content: space-between
    align-items: center 
    margin-bottom: 24px
    .title-a
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333
      .tip 
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FD220F;
    .search
      width 480px
      height: 40px;
      box-sizing: border-box
    .el-input-group__append   
      color #fff
      background: #316FFF;
      height: 40px;
      border none
  .subscribe-list
    width: 1200px
    .subscribe-item
      width 1200px
      height: 152px;
      background: #FFFFFF;
      border: 1px solid #E4E7ED
      box-sizing: border-box
      padding-top 20px
      margin-bottom: 17px
      .title-2
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding-left 16px

      .address
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666 
        padding-left 16px
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        padding 9px 0 18px 16px
      .bottom
        display flex 
        justify-content: space-between
        height: 55px;
        background: #F7F7F7
        width 1198px
        align-items: center
        .address-2
          flex 1
          padding-left 16px
        .btn
          width: 88px;
          height: 32px;
          background: #1972FF;
          border-radius: 4px;
          text-align: center 
          line-height: 32px 
          margin-right 16px 
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF
          cursor pointer
  .list-none
    display flex 
    flex-direction: column 
    align-items: center
    img
      display: block;
      width 365px
      height 220px
    p
      text-align: center;
      margin-top: 8px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
  .dialog
    width 400px
    // box-sizing: border-box
    padding 40px 0 30px
    .icon-img
      font-size: 50px
      text-align: center
    .titles
      padding 19px 0 17px
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1B2257
      text-align: center
    .text
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center 
      padding-bottom 30px
    .bottom-btn
      display: flex
      width 400px 
      justify-content: center
      .left-btn
        width: 120px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #D7DCEE;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 40px
        box-sizing: border-box
        text-align: center 
        &:hover
          cursor pointer
        margin-right 15px
      .right-btn
        width: 120px;
        height: 40px;
        margin-left 15px
        text-align: center 
        background: linear-gradient(211deg, #5E9DFE 0%, #1972FF 100%) #316FFF;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px
        &:hover
          cursor pointer

          
         

</style>