<template>
  <div class="pagenation-wraper">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.pageNum"

      :page-sizes="[10, 20, 30]"
      :page-size="pageInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    props: {
      pageInfo: {
        type: Object,
        default: function() {
          return {}
        }
      },
    },
    data() {
      return {
        currentPage: 1,
        currentSize: 10, 
      }
    },
    methods: {
      handleSizeChange(val) {
        if(val === this.currentSize) {
          return  
        }
        this.currentSize = val
        this.$emit('numChange', {currentPage: this.currentPage, currentSize: this.currentSize })
      },
      handleCurrentChange(pageNum) {
        // if(pageNum === this.currentPage) {
        //   return 
        // }
        this.currentPage = pageNum
        this.$emit('numChange', {currentPage: this.currentPage, currentSize: this.currentSize })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.pagenation-wraper
  margin 48px auto;
  display: flex
  justify-content: center
  /deep/.el-pagination
    display inline-block
</style>
