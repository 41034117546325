<template>
    <div class="shuidian-home">
        <div class='homeBanner'>
            <el-carousel trigger="click">
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
                <img :src="item.imgUrl" alt="">
            </el-carousel-item>
            <img :src="imgHeight" class="toolImg" alt="">
            </el-carousel>
        </div>
        <div class="content">
            <div class="step1">
                <div class="title">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/head1.png" class="head" alt="">
                </div>
                <div class="step1-img" @click="goLearning">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step1-img.png" alt="">
                </div>
            </div>
            <div class="step2">
                <div class="title">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/head2.png" class="head" alt="">
                </div>
                <div class="step2-con">
                    <div class="item1" v-for="item in step2" :key="item.id">
                        <img :src="item.url" alt="">
                    </div>
                </div>
            </div>
            <div class="step3">
                <div class="title">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/head3.png" class="head" alt="">
                </div>
                <div class="step3-img">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step3-img.png" alt="">
                </div>
            </div>
            <div class="step1 step4">
                <div class="title">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/head4.png" class="head" alt="">
                </div>
                <div class="step1-img">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step4-img1.png" alt="">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step4-img2.png" alt="">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step4-img3.png" alt="">
                </div>
            </div>
        </div>
        
        <shuidianFooter />
    </div>
</template>

<script>
// import weidongFooter from './weidongFooter'
// import Swiper from "swiper";
// import "swiper/dist/css/swiper.min.css";
import shuidianFooter from './footer.vue'
export default {
  name: 'weidong_homepage',
    data() {
        return {
            step2:[
                {
                    id:1,
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step2-img1.png'
                },
                {
                    id:2,
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step2-img2.png'
                },
                {
                    id:3,
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step2-img3.png'
                },
                {
                    id:4,
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step2-img4.png'
                },
                {
                    id:5,
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step2-img5.png'
                },
                {
                    id:6,
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step2-img6.png'
                },
                {
                    id:7,
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step2-img7.png'
                },
                {
                    id:8,
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/step2-img8.png'
                }
            ],
             imgList: [
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/home-banner1.png',
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/home-banner2.png',
                }
            ],
            imgHeight: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/home-banner1.png',
            liveObj:[],
        };
    },
    components:{
        shuidianFooter
    },
    mounted() {
    
    },
    created() {
        
    },
    methods: {
        /**
         * 跳转培训任务
         */
        goLearning(){
            if(window.location.hostname.includes('testing3')){
                this.$router.push({
                    path:'/learning/taskDetail',
                    query:{
                        backMoudle:'trainingTask',
                        id:20029,
                        validity:true,
                        backPath:'/learning'
                    }
                })
                // window.open('https://sdsj.wdn-pc.testing3.wdeduc.com/learning/taskDetail?backMoudle=trainingTask&id=20029&validity=true&backPath=%2Flearning','_self')
            }else {
                this.$router.push({
                    path:'/learning/taskDetail',
                    query:{
                        backMoudle:'trainingTask',
                        id:32352,
                        validity:true,
                        backPath:'/learning'
                    }
                })
                // window.open('https://sdsj.wdeduc.com/learning/taskDetail?backMoudle=trainingTask&id=32289&validity=true&backPath=%2Flearning','_self')

            }
        },
        goEvaluation(){
            if(localStorage.getItem('token')){
                this.$router.push('/assessList')
            } else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: '/assessList'
                    }
                })
            }
        },
        // 跳转直播列表
        goLive(){
            if(localStorage.getItem('token')){
                this.$router.push('/liveList')
            } else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: '/liveList'
                    }
                })
            }
        }
    }
};
</script>
<style lang="stylus" scoped>
.shuidian-home{
    .content{
        width 1200px
        margin 0 auto;   
        text-align: center; 
        .head{
            width 1200px    
        }
        .step1{
            .title{
                margin-top 82px
                margin-bottom 50px    
            }
            .step1-img img{
                width 1200px
                cursor pointer
            }    
        }
        .step2{
            margin-top 82px
            .title {
                margin-bottom 50px    
            } 
            .step2-con{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .item1{
                    margin-bottom 24px    
                }
            }
        }
        .step3{
            margin-top 62px   
            .title{
                margin-bottom 50px    
            } 
        }
        .step4 {
            margin-bottom 150px
            .step1-img{
                display: flex;
                justify-content: space-between;
                img{
                    width 376px
                    vertical-align: middle;
                    cursor: default;
                }    
            }    
        }
    }
}
.homeBanner {
  .toolImg{
    width: 100%;
    object-fit: cover;
    opacity: 0;
  }
}
   // 兼容谷歌
.homeBanner {
  /deep/.el-carousel__indicators .el-carousel__indicators--horizontal {
    bottom: 80px!important;
  }
  .el-carousel__container{
    height: auto !important
    img {
      width: 100%!important;
      object-fit: cover
    }
  }
  /deep/.el-carousel__indicators--horizontal {
    bottom: 23px;
    // display: none;
    .el-carousel__indicator--horizontal {
      padding: 12px 10px;
      .el-carousel__button {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }
  // 兼容火狐
  @-moz-document url-prefix() {
    /deep/.el-carousel__container{
        height: auto !important
        img {
            width: 100%!important;
            object-fit: cover
        }
    }
    /deep/.el-carousel__indicators--horizontal {
      bottom: 23px;
      // display: none;
      .el-carousel__indicator--horizontal {
        padding: 12px 10px;
        .el-carousel__button {
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
      }
    }
  }
  // 兼容IE
  /deep/.el-carousel__container{
    height: auto !important
    img {
      width: 100%!important;
      object-fit: cover
    }
  }
  /deep/.el-carousel__indicators--horizontal {
    bottom: 23px;
    // display: none;
    .el-carousel__indicator--horizontal {
      padding: 12px 10px;
      .el-carousel__button {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }
}
</style>
